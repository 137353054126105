/* header */
:root {
    --sidebar-width: 200px;
}

.header {
    width: 100%;
    height: 60px;
    /* background-color: #112c4c; */
    /* background-color: #d9ebf8; */
    /* outline: 2px solid #dfdfdf; */
    position: sticky;
    top: 0px;
    z-index: 999;
    background: linear-gradient(90deg, #0c2646 0, #053163 60%, #0c2646);
}
.web_header{
    width: 100%;
    height: 60px;
    /* background-color: #ffffff; */
    /* outline: 2px solid #dfdfdf; */
    position: sticky;
    top: 0px;
    z-index: 999;
}
.web_headerLight{
    background-color: #e7e7e7;
}
.web_headerDark{
    background-color: #141921;
}
.btn-search {
    border: none !important;
}

/* Sidebar */
.sidebar {
    position: fixed;
    top: 60px;
    left: 0px;
    width: 75px;
    font-size: 13px;
    bottom: 0px;
    padding-top: 5px;
    overflow-y: auto;
    transition: 0.5s;
}
.sidebarDark{
    background-color: #141921;
    color: white;
}
.sidebarLight{
    background-color: #e7e7e7;
    /* background-color: #d9ebf8; */
    color: black;
}
.sidebar .menu-title {
    font-size: small;
}

.sidebar.open {
    width: var(--sidebar-width);
    padding: 10px;
    font-size: medium;
}

.sidebar.open .menu-title {
    font-size: unset;
}

.sidebar.open .nav-link {
    text-align: left;
    display: flex;
    align-items: center;
    border-radius: 25px;
}

.sidebar.open .nav-link svg {
    margin-right: 10px;
}

.sidebar .nav-link {
    padding: 0.7rem;
    text-align: center;
}

/* .sidebarDark .nav-link:hover {
    background-color: green !important;
    color: white;
}
.sidebarLight .nav-link:hover {
    background-color: green !important;
    color: white;
} */
.sidebarDark .nav-link.active {
    background-color: #00477b !important;
    color: white;
}
.sidebarLight .nav-link.active {
    background-color: #00477b !important;
    color: white;
}

/* App content */
.content {
    position: relative;
    left: 70px;
    width: calc(100% - 70px);
    transition: 0.5s;
}

.content.open {
    left: var(--sidebar-width);
    width: calc(100% - var(--sidebar-width));
    /* overflow-x: hidden; */
}

.content.queue.open{
    left: var(--sidebar-width);
    width: calc(75% - var(--sidebar-width));
}

.content.queue{
    left: 70px;
    width: calc(75% - 70px);
}

/* pages */
.card-audio {
    height: 20vh;
    object-fit: cover;
    border: 0px !important;
}

.card-video {
    height: 25vh;
    width: 100%;
    object-fit: cover;
}
/* Admin content */
.admin-content {
    position: relative;
    left: 200px;
    width: calc(100% - 200px);
}

.admin-content-all {
    position: relative;
    left: 0px;
    width: 100%;
}

.adminHeader {
    position: relative;
    left: 200px;
}

/* login page */
.bg-image {
    /* background-image: url('../src/assets/images/bg.jpg'); */
    background: linear-gradient(#00000049, #0000004f), url('../src/assets/images/bg4.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.login-form {
    width: 100%;
    max-width: 400px;
    box-shadow: 4px 4px 8px 10px rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background-color: rgba(255, 255, 255, 0.568) !important;
    backdrop-filter: blur(15px);
}

.bg-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.container-fluid1 {
    position: relative;
    overflow: hidden !important;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100vh;
}

/* footer */
.audioFooterLight {
    background-color: #d5d5d5;
    color: black !important;
}
.audioFooterDark {
    background-color: #141921;
    color: white !important;
}

.audioControl {
    color: red !important;
}

.z_content {
    position: relative;
    z-index: 11;
}

.audioImage {
    border-radius: 10px;
    margin-left: 10px;
}

.rhap_download-progress {
    background-color: #ffad7d !important;
}

.rhap_progress-indicator {
    background: #f06b20 !important;
}

.rhap_progress-filled {
    background-color:#f06b20 !important;
}

.rhap_main-controls-button {
    color: #f06b20 !important;
}

.rhap_repeat-button {
    color: #f06b20 !important;
}

.rhap_volume-button {
    color: #f06b20 !important;
}

.rhap_volume-indicator {
    background: #f06b20 !important;
}

.rhap_volume-bar {
    background: #b1aeae !important;
}
.rhap_time{
    color: #f06b20 !important;
}
.rhap_progress-bar-show-download{
    background: #b1aeae !important;
}
.lyricsTextColor{
    color: #00477b !important;
}

.dropdownWidth {
    width: 195px;
    z-index: 9999 !important;
}
.dropdownWidthDark{
    color: white;
    background: linear-gradient(45deg, #0e0d0df6, #0e0d0dc9);
}
.globalSearch .form-control {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-color: rgb(194, 194, 193);
}

.globalSearch .form-control:focus, .globalSearch .form-control:active {
    outline: none !important;
    box-shadow:none !important;
}

.globalSearch .searchIcon {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-color: rgb(214, 214, 214);
    /* background-color: white; */
}
.globalSearch .searchIconLight{
    border-color: rgb(197, 197, 197);
    background-color: white !important;
}
.globalsearchInput{
    background: transparent;
    border-color: #787474;
    color: white;
}
.globalsearchInput::placeholder{
    color: white;
}
.globalsearchInput:focus{
    background: transparent;
    border-color: #787474;
    color: white;
}

/* Load more css */
.loadMore {
    width: 120px;
    color: #ffff;
    display: block;
    text-align: center;
    margin: 20px auto;
    padding: 5px;
    border-radius: 15px;
    border: 1px solid transparent;
    background-color: #c91743;
    transition: .3s;
    font-size: small;
}

.loadMore:hover {
    color: #c91743;
    background-color: #fff;
    border: 1px solid rgb(148, 0, 37);
    text-decoration: none;
}

.form-control {
    font-size: 0.75rem !important;
}

/* side bar logout button */
.logout-btn {
    position: fixed;
    bottom: 0px;
    left: 0px;
}

.logout-btn button{
    border-radius: 0px !important;
}

.sidebar .logout-btn {
    width: 75px;
}

.sidebar.open .logout-btn {
    width: var(--sidebar-width);
}

.sidebar .logout-btn .logout-text{
    display: none;
}
.sidebar.open .logout-btn .logout-text{
    display: inline;
}

.btn-close, .btn-close:hover, .btn-close:focus {
    outline: none;
  box-shadow: none;
}
.header_dropdown{
    padding: 10px;
    columns: 3;
    max-height: 65vh;
    overflow-y: auto;
}
.header_dropdownDark{
    backdrop-filter: blur(5px);
    background: linear-gradient(90deg,rgba(39, 43, 61, 0.5) 0%,rgba(37, 41, 58, 0.5) 100%);
}
@media screen and (max-width: 767px) {
    .header_dropdown{
        columns: 2;
        position: absolute;
        inset: 0px auto auto 0px;
        margin: 0px;
        transform: translate3d(-206.5px, 33px, 0px);
    }   
    .header_translate{
        columns: 2;
        position: absolute;
        inset: 0px auto auto 0px;
        margin: 0px;
        transform: translate3d(-155px, 33px, 0px);
    }   
}
@media screen and (max-width: 576px) {
    .sidebar {
        top: 85px;
    }    
}
.dropdown-item{
    color: white !important;
}
.dropdown-itemLight{
    color: black !important;
}
.dropdown-item:focus, .dropdown-item:hover{
    background-color: #f06b20 !important;
    border-radius: 10px;
}
.dropdown-itemLight:focus, .dropdown-itemLight:hover{
    background-color: #f06b20 !important;
    border-radius: 10px;
    color: white !important;
}

/* BUbble effect if need it include it */
.bubble {
    position: absolute;
    width: 12px;
    height: 12px;
    /* background-color: rgba(235, 33, 99, 0.7); */
    /* background: radial-gradient(circle, rgba(235,33,99,1) 0%, rgba(255,193,7,1) 100%); */
    /* background: radial-gradient(circle, rgba(235,33,99,1) 10%, rgba(255,193,7,1) 100%); */
    border-radius: 50%;
    pointer-events: none;
    animation: bubbleAnimation 1.5s ease-out;
    z-index: 9999;
    transform: translate(-50%, -50%); /* Center the bubble on the cursor */
  }
  
  @keyframes bubbleAnimation {
    0% {
      transform: translate(-50%, -50%) scale(1);
      opacity: 1;
    }
    100% {
      transform: translate(-50%, -50%) scale(2);
      opacity: 0;
    }
  }
.helpBtn{
    position: fixed;
    right: 10px;
    bottom: 20px;
    z-index: 9999;
    background-color: #00477b;
    color: white;
}
.helpBtn:hover{
    background-color: #00477b;
    color: white;
}
.helpCard{
    background: linear-gradient(90deg,rgb(12, 12, 12) 0%,rgb(43, 43, 44) 100%);
    backdrop-filter: blur(5px);
    color: white;
}
.helpCardLight{
    background: white;
    backdrop-filter: blur(5px);
    color: black;
}
.helpInput{
    background: transparent;
    border-color: gray !important;
    color: white !important; 
}
.helpInput::placeholder{
    color: white !important;
}
.helpInput:focus{
    background: transparent;
    border-color: gray !important;
    color: white !important;
}
.helpInput:disabled{
    background: transparent;
    border-color: gray !important;
    color: white !important;
}
.user-profile-select .css-13cymwt-control {
    background: transparent;
}
.helpView{
    position: fixed;
    right: 10px;
    bottom: 20px;
    z-index: 9999;
    width: 300px;
}
.descOverflow{
    max-height: 250px;
    overflow-y: auto;
}
.focusRemove:focus, .focusRemove:hover{
    outline: none !important;
    border-color: lightgray !important;
    box-shadow: none !important;
}

/* header */
.clear-language {
    position: absolute;
    right: -8px;
    top: -7px;
    padding: 0px 5px;
    cursor: pointer;
}

@media screen and (max-width: 768px) {
    .header {
        height: max-content;
    }
    .web_header{
        height: max-content;
    }
    .clear-language {
        position: absolute;
        right: -12px;
        top: -9px;
        padding: 0px 5px;
        font-size: x-small;
        cursor: pointer;
    }

    .sidebar {
        padding: 0px;
        width: 0px;
        left: 0px;
        display: none;
    }

    .sidebar.open{
        display: block;
        z-index: 99;
    }

    .content, .content.open{
        left: 0px;
        width: 100%;
    }
}
.audiolist {
    max-height: 68vh;
    overflow: hidden;
    overflow-y: auto;
}
@media screen and (max-width: 768px) {
    .audiolist {
        max-height: 68vh;
        overflow-y: auto;
        overflow-x: auto;
    }
}

.clear-search{
    position: absolute;
    right: 55px;
    top: 10px;
}

/* Admin page profile modal */
.profile-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    /* z-index: 1050; */
    z-index: 9999;
}

.profile-modal-content {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    padding: 5px;
    /* border-radius: 10px; */
    /* max-width: 80%;
    max-height: 80%;
    overflow: auto */
    /* border-radius: 50%; */
}
.viewAllColor{
    color: #f06b20;
}
.viewBgColor{
    background-color: #f06b20;
}
.profileTab{
    width: 60%;
}
.viewCountClr{
    color: #d106a5 !important;
}
.actionBtn{
    background-color: #f06b20;
    color: white;
}
.actionBtn:hover{
    background-color: #f06b20;
    color: white;
}
.actionBtn:disabled {
    background-color: #f06b20;
    color: white;
    opacity: 0.7;
    cursor: not-allowed;
}

.videoCard{
    border-radius: 3px !important;
    /* overflow: hidden; */
}
/* .videoCard:hover{
    transform: scaleX(1.1) scaleY(1.1);
    transition: 0.5s;
} */

.videoCard:hover .video_card_bg{
    background-color: #2f2f2f !important;
}
.modelBgColor{
    background-color: black;
    color: white;
}
.signInBtn{
    background-color: #00477b !important;
    color: white !important;
}
.signInBtnDark:hover{
    color: black !important;
}
/* @keyframes appear {
    from{
        opacity: 0;
        scale: 0.5;
    }
    to{
        opacity: 1;
        scale:1;
    }
}
@keyframes homeAppear {
  0%   {opacity: 0;}
  25%  {opacity: 0.5;}
  50%  {opacity: 0.7;}
  100% {opacity: 1;}
}
.animate_videos{
    animation: appear linear, homeAppear 1.5s !important;
    animation-timeline: view();
    animation-range: entry 0% cover 25%;
}
.animateHome{
    animation: homeAppear linear;
    animation-duration: 1.5s;
} */
/* .page-content {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0s, transform 0.7s;
  }
  
  .page-content.entered {
    opacity: 1;
    transform: translateY(0);
  }
  
  .page-content.exiting {
    opacity: 0;
    transform: translateY(-20px);
  } */

/* scrollbar */
@media screen and (min-width: 768px) {
::-webkit-scrollbar {
    height: 5px;
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
  ::-webkit-scrollbar-thumb {
    background: #a5a5a5; 
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }
  }
.headingHover:hover{
    text-decoration: underline !important;
}
.plylist-count{
    position: absolute;
    bottom: 2px;
    right: 4px;
    background-color: #000000ad;
    line-height: normal;
    font-size: small;
    padding: 5px;
    border-radius: 4px;
  }
.similar-artist{
    height: 50px;
    width:50px;
    background-repeat: no-repeat;   
    background-size: cover;
    object-fit: fill;
    background-position: center;
    border: 0px !important;
    box-shadow: #f3f2f2cb 1px 1px 1px 1px;
}
.terms_txt:hover{
    color: white !important;
}
.terms_txt_light:hover{
    color: black !important;
}
.tag{
    display: inline-block;
    margin: 5px;
    padding: 5px 10px;
    border-radius: 15px;
    white-space: pre-wrap;
    border: 1px solid;
}
.detailViewImageFooter {
    height: 250px;
    width: 100%;
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
    border: 0px !important;
}
@media screen and (max-width: 768px) {
    .detailViewImageFooter {
        height: 100px;
        width: 100%;
    }
}
.audioCardHoverDark{
    background-color: #0d0e0e;
    border: #141212 1px solid;
    margin-left: 0px !important;
    border-radius: 5px;
}
.audioCardHoverDark:hover{
    background-color: #020e16;
}

.audioCardHoverLight{
    background-color: #e7dcdc;
    border: #e5e5e5 1px solid;
    border-radius: 5px;
}
.audioCardHoverLight:hover{
    background-color: #c0d0db;
}
.policy_active{
    color: #f06b20 !important;
}
.policy_container{
    background-color: #141921 !important;
    color: white !important;
}
.policy_container_light{
    background-color: #e7dcdc !important;
    color: black !important;
}
.addBottomSpace{
    margin-bottom: 50px !important;
}
.animationFadeIn{
    animation-name: fadeIn;
    animation-duration: 1.5s;
    /* animation-fill-mode: both; */
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.adminEdit{
    bottom: 5px;
    left: 69%;
    transform: translateX(-50%);
    cursor: pointer;
    padding: 5px;
    border-radius: 50%;
    background: #f76232;
}

.admin-user-profile {
    margin-left: -30px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}