.description {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.5;
    /* Adjust as needed */
    height: calc(1.5em * 1);
}
.searchDesc{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.5;
    /* Adjust as needed */
    height: calc(1.5em * 2);
}

.detailViewImage {
    height: 150px;
    width: 150px;
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
    border: 0px !important;
}
.listSongs:hover{
    cursor: pointer;
    background-color: #00477b !important;
    color: white;
    transition: 0.5s;
}
@media screen and (max-width: 768px) {
    .countData{
        text-align: center !important;
        display: none !important;
    }
    .detailViewImage {
        height: 100px;
        width: 100px;
    }
}

.playingSVG{
    position: absolute;
    top: 15px;
    left: 10px;
}
.image-wrapper{
    position: relative;
    display: inline-block;
    overflow: hidden;
}
.play-overlay{
    position: absolute;
    background-color: #00000094;
    width: 50px;
    height: 50px;
    top: 0px;
    left: 0px;
}
@keyframes audioDetail {
    from {top: 0px;}
    to {top:60px}
  }
.detailView{
    position: fixed;
    top: 60px;
    right: 0;
    width: 100%;
    bottom: 85px;
    border: none;
    border-radius: 0px;
    background-color: #0e2533;
    color: white;
    animation-name: audioDetail;
    animation-duration: 1s;
}
.queueListView{
    position: fixed;
    top: 60px;
    right: 0;
    width: 25%;
    bottom: 85px;
    border: none;
    border-radius: 0px;
    background-color: #0e2533;
    color: white;
}
@media screen and (max-width: 768px) {
    .queueListView{
        top: 95px;
        width: 100%;
    }
   .detailView{
        top: 95px;
        width: 100%;
    }
}
.queue_active{
    color: #f06b20 !important;
}

@media screen and (max-width: 768px) {
.rhap_time {
font-size: 12px;
}
.rhap_progress-indicator {
    width: 10px;
    height: 10px;
    top: -3px;
    margin-left: -7px;
}
.rhap_stacked .rhap_controls-section {
    margin-top: -10px;
    margin-bottom: -5px;
}
.rhap_volume-button {
    font-size: 20px;
}
.rhap_volume-indicator {
    width: 8px;
    height: 8px;
    top: -2px;
}
.rhap_play-pause-button {
    font-size: 25px;
}
.rhap_main-controls-button {
    font-size: 25px;
    margin: 0px;
}
.rhap_container {
    padding: 0px 15px;
    line-height: 0;
}
.rhap_repeat-button {
    font-size: 20px;
}
}
.video_card_bg{
    background-color: black;
}
.logout_btn{
    background-color: #00477b;
    color: white;
}
.logout_btn:hover{
    background-color: #00477b;
    color: white;
}
.viewAllColor{
    color: #f06b20;
}