.main-sidebar{
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 200px;
    bottom: 0;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: #dfe0e4 !important;
}
.sidebar-closed{
    width: 0px;
    display: none;
    position: fixed;
}

.avatar_profile{
    width: 100px;
}
.nav-link-sidebar.active, .nav-link-sidebar:hover{
    background-color: #112c4c !important;
    border-radius: 10px;

}
.menu-item{
    color: black !important;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.nav-link-sidebar.active .menu-item, .nav-link-sidebar:hover .menu-item{
    /* color: #2141fa; */
    color: white !important;
    font-weight: 600;
}
.adminHomeCard{
    height: 90px;
    width: 100%;
}
.accordion-button {
padding: 8px !important;
}

.video-container {
    width: 100%;
}
.responsive-video {
    width: 100%;
    height: 30vh;
}
.responsive-ad{
    height: 30vh;
}
.responsive-audio {
    width: 100%;
}
.spinner_loading{
    z-index: 999;
    position: absolute;
    top: 50%;
    left: 0px;
}
.artistTable .bOmZm{
    width: 20px;
    padding-right: 10px !important;
}
.artistTable .dOTSzF{
    padding: 4px !important;
    width: fit-content !important;
}
.artistTable .bRGCiY {
    font-size: 12px;
}
.artistTable .jYgXvU{
    margin: 0px;
}
.artistTable .kbPrZo {
    margin: 0px;
}

/* filter select style */
.filter-select .css-13cymwt-control, .css-t3ipsp-control{
    min-height: auto !important;
}
.filter-select .css-1xc3v61-indicatorContainer{
    padding: 0px !important;
}
.filter-select .css-15lsz6c-indicatorContainer{
    padding: 0px !important;
}

.addons-card{
    box-shadow: 1px 1px 3px 2px #d3d3d3;
    border-color: #fff !important;
}

.addons-card .rdt_Table{
    border: none !important;
}
.adSelect .form-select{
    font-size: small !important;
}
/* home page  */
.card_height{
    height: 19vh;
    /* box-shadow: 8px 6px 5px rgba(209, 208, 208, 0.75); */
    border: none;
}
.card_shadow{
    /* box-shadow: 8px 8px 9px rgba(206, 206, 206, 0.75); */
    border-radius: 5px;
    background-color: #fff;
}
/* .home_bg{
    background-color: #e9e9e9;
} */
.totalUser{
    background: linear-gradient(45deg, #e5f4fb, #45adf1);
}
.totalAdmin{
    background: linear-gradient(45deg, #f6f0fb, #d65deb);
}
.totalRegion{
    background: linear-gradient(45deg, #f6f0fb, #ad7d6c);
}
.totalMusician{
    background: linear-gradient(45deg, #eaf1ef, #f5a811);
}
.totalEndUser{
    background: linear-gradient(45deg, #f9f6ef, #ff7e94);
}
.totallang{
    background: linear-gradient(45deg, #f9f6ef, #53b5b0);
}
.viewCountTitle{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.5;
    /* Adjust as needed */
    height: calc(1.5em * 1);
}
.hearColor{
    color: #ff7e94;
}
.eyeColor{
    color: #45adf1;
}
.videoColor{
    color: #6da400;
}
.audioColor{
    color: #f5a811;
}
.adminTxtColor{
    color: #f76232 !important;
}
.adminBtn{
    background-color: #f76232 !important;
    color: white !important;
}
.adminBtn:hover{
    background-color: #f76232 !important;
    color: white !important;
}
.modal_title_bg{
    background-color:#0f3463 !important;
    color: white !important;
}

.clear-date::after{
    background-color: #fff !important;
    color: #b8b8b8 !important;
    font-size: x-large !important;
    font-weight: bold;
}

.react-datepicker__input-container:focus-within .clear-date::after {
    color: #5e5e5e !important;
}

.react-datepicker-wrapper {
    display: block !important;
}